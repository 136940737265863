import * as React from 'react';
import {graphql, Link} from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/layout';
import Seo from '../components/seo';
import HeroSection from '../components/HeroSection';
import HeroBreadcrumb from '../components/HeroBreadcrumb';

const BlogIndex = ({data, location}) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMarkdownRemark.nodes;

  if (posts.length === 0) {
    return (
        <Layout location={location} title={siteTitle}>
          <Seo title="All posts"/>
          <Bio/>
          <p>
            No blog posts found.
          </p>
        </Layout>
    );
  }

  return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts"/>
        <HeroBreadcrumb pageTitle="Welcome to my online gym workout!"/>
        <div style={{
          textAlign: 'center',
          maxWidth: '700px',
          margin: '40px auto',
        }}>
          <h1>Fitness planner - welcome</h1>
          <h2>Coming soon - interactive React scheduler for personal
            trainers. </h2>

          <p>Here are some of our most popular blog posts</p>


          <li><Link to="/top-shoulder-gym-workout-routines-and-exercises/">Best shoulder gym workout exercise routines</Link></li>
          <li><Link to="/3-day-a-week-workout-routine-monday-wednesday-friday/">3  times a week workout routine</Link></li>
          <li><Link to="/best-7-day-workout-routine/">Complete 7 day workout routine</Link></li>
          <li><Link to="/best-back-workout-routine/">Best back workout routine</Link></li>
          <li><Link to="/chest-workout-routine/">How to start an exercise routine</Link></li>
          <li><Link to="/leg-day-workout-routine/">Best leg workout routine</Link></li>
          <li><Link to="/best-30-minute-workout-routine/">Best full body 30 minute workout routine</Link></li>
          <li><Link to="/workout-routine-for-gym-beginners/">Workout routine for gym beginners</Link></li>
          <li><Link to="/resistance-band-training-workout-routines/">Resistance band training exercises and workout routine</Link></li>
          <li><Link to="/30-minute-bodyweight-home-workout-plan/">30 Minute bodyweight workout at home</Link></li>
          <li><Link to="/5-day-a-week-workout-routine/">5 Day a week workout  routine for muscle growth (5 day split workout)</Link></li>
          <li><Link to="/best-abs-workout-routine-and-exercises/">Best abs workout routine and exercises</Link></li>
          <li><Link to="/get-in-shape-without-a-gym/">Get in shape without a gym</Link></li>
          <li><Link to="/how-to-build-your-workout-routine/">How to build your workout routine</Link></li>
          <li><Link to="/how-to-start-exercising-routine/">Best abs workout routine and exercises</Link></li>
          <li><Link to="/strength-training-workout-routine/">Strength training workout routine for beginners</Link></li>
          <li><Link to="/upper-body-workout-routine-for-beginners/">Upper body workout routine for beginners</Link></li>
          <li><Link to="/best-warm-up-exercises-workout-plan/">Best warm up exercises to do in your workout plan</Link></li>
        </div>
      </Layout>
  );

  return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts"/>

        {/* Hero-section */}

        <HeroSection/>

        {/* /Hero-section */}
        {/* Feature-Area */}
        <div className="feature-area py-120">
          <div className="container">
            <div className="row center">
              <div className="col-md-6 col-lg-4">
                <div className="feature-item rotate_right">
                  <div className="feature-icon">
                    <i className="flaticon-machine"/>
                  </div>
                  <div className="feature-content">
                    <h4>Best gym equipment to work out with</h4>
                    <p>Find the best type of gym equipment to maximise your work
                      out results</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="feature-item active">
                  <div className="feature-icon">
                    <i className="flaticon-muscle"/>
                  </div>
                  <div className="feature-content">
                    <h4>Full workout regime</h4>
                    <p>Find the best workout regimes for you - for all aims and
                      goals</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="feature-item rotate_left">
                  <div className="feature-icon">
                    <i className="flaticon-sports"/>
                  </div>
                  <div className="feature-content">
                    <h4>And don't forget food</h4>
                    <p>Diet is the most important part - find out what diet plan
                      you should be on</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Feature-Area */}
        {/* About Area */}
        {/* <div className="about-area pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-left">
                <div className="site-heading mb-3">
                  <span className="site-title-tagline">About Us</span>
                  <h2 className="site-title">We Are Ready <span>To Making You Different</span> From Others
                  </h2>
                </div>
                <p className="about-text">There are many variations of passages of Lorem Ipsum available, but
                  the majority have suffered alteration in some form, by injected humour, or randomised
                  words which don't look even slightly believable. If you are going to use a passage of
                  orem psum you need to be sure.All the Lorem Ipsum generators on the Internet tend to
                  repeat predefined chunks.</p>
                <div className="about-list-wrapper">
                  <ul className="about-list list-unstyled">
                    <li>
                      <div className="icon"><i className="flaticon-people" /></div>
                      <div className="text">
                        <h4>Qualified Instructor</h4>
                        <p>Take a look at our round up of the best shows.</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon"><i className="flaticon-stationary-bike" /></div>
                      <div className="text">
                        <h4>Get Fitness Training</h4>
                        <p>It has survived not only five centuries.</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <Link to="about.html" className="theme-btn">Discover More <i className="far fa-arrow-right" /></a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-right">
                <div className="about-img">
                  <img src="assets/img/about/about.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
        {/* /About Area */}
        {/* Class-Timetable */}


        {/* CTA-Area */}
        {/* <div className="cta-area">
        <div className="container">
          <div className="cta-content">
            <h2>Join ourMonthly Membership</h2>
            <h6>We Are More Effective To Making Different Yourself From Others</h6>
            <Link to="#" className="theme-btn">Join Us Now <i className="far fa-arrow-right" /></a>
          </div>
        </div>
      </div> */}
        {/* /CTA-Area */}


        {/* /Testimonial-Area */}
        {/* Blog-area */}
        <div className="blog-area pt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="site-heading text-center">
                  <span className="site-title-tagline">Our Blog</span>
                  <h2 className="site-title">Latest blog posts</h2>
                  <div className="heading-divider"/>
                  <p>
                    Check out the latest gym workouts below
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="blog-item">
                  <div className="blog-item-img">
                    <img src="assets/img/blog/01.jpg" alt="Thumb"/>
                  </div>
                  <div className="blog-item-info">
                    <div className="blog-item-meta">
                      <ul>
                        <li><Link href="#"><i
                            className="far fa-user-circle"/> By Alicia
                          Davis</Link></li>
                        <li><Link href="#"><i
                            className="far fa-calendar-alt"/> August 21,
                          2019</Link></li>
                      </ul>
                    </div>
                    <h4 className="blog-title">
                      <Link to="/blog">The best workout routines for large
                        arms</Link>
                    </h4>
                    <Link className="blog-btn" to="/blog">Read More <i
                        className="far fa-arrow-right"/></Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="blog-item">
                  <div className="blog-item-img">
                    <img src="assets/img/blog/02.jpg" alt="Thumb"/>
                  </div>
                  <div className="blog-item-info">
                    <div className="blog-item-meta">
                      <ul>
                        <li><Link href="#"><i
                            className="far fa-user-circle"/> By Alicia
                          Davis</Link></li>
                        <li><Link href="#"><i
                            className="far fa-calendar-alt"/> August 21,
                          2021</Link></li>
                      </ul>
                    </div>
                    <h4 className="blog-title">
                      <Link to="/blog">The type of push up you need to start
                        doing</Link>
                    </h4>
                    <Link className="blog-btn" to="/blog">Read More <i
                        className="far fa-arrow-right"/></Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="blog-item">
                  <div className="blog-item-img">
                    <img src="assets/img/blog/03.jpg" alt="Thumb"/>
                  </div>
                  <div className="blog-item-info">
                    <div className="blog-item-meta">
                      <ul>
                        <li><Link href="#"><i
                            className="far fa-user-circle"/> By Alicia
                          Davis</Link></li>
                        <li><Link href="#"><i
                            className="far fa-calendar-alt"/> March 12,
                          2020</Link></li>
                      </ul>
                    </div>
                    <h4 className="blog-title">
                      <Link to="/blog">Workouts at home</Link>
                    </h4>
                    <Link className="blog-btn" to="/blog">Read More <i
                        className="far fa-arrow-right"/></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Blog-area */}
        {/* Partner-Area */}
        <div className="partner-area pt-70 pb-70">
          <div className="container">
            <div
                className="partner-wrapper partner-slider owl-carousel owl-theme">
              <img src="assets/img/partner/1.png" alt="thumb"/>
              <img src="assets/img/partner/2.png" alt="thumb"/>
              <img src="assets/img/partner/3.png" alt="thumb"/>
              <img src="assets/img/partner/4.png" alt="thumb"/>
              <img src="assets/img/partner/5.png" alt="thumb"/>
              <img src="assets/img/partner/6.png" alt="thumb"/>
              <img src="assets/img/partner/3.png" alt="thumb"/>
            </div>
          </div>
        </div>
        {/* /Partner-Area */}

      </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`;
